import linkedin from "../assets/imgs/linkedin.svg";

export const LinkedinIcon = () => {
  return (
    <a
      href="https://www.linkedin.com/in/kloppz/"
      rel="noreferrer"
      target="_blank"
    >
      <img src={linkedin} alt="linkedin logo" />
    </a>
  )
}