import twitter from "../assets/imgs/twitter.svg";

export const TwitterIcon = () => {
  return (
    <a
      href="https://twitter.com/FoxkDev"
      rel="noreferrer"
      target="_blank"
    >
      <img src={twitter} alt="twitter logo" />
    </a>
  )
}