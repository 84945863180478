import github from "../assets/imgs/github.svg";

export const GithubIcon = () => {
  return (
    <a
      href="https://github.com/foxkdev"
      rel="noreferrer"
      target="_blank"
    >
      <img src={github} alt="github logo" />
    </a>
  )
}